<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-12
          input.btn.btn-primary.col.mb-5.mt-5(type="submit" @click="callRecordEdit(null)" value="記録を作成する") 
          .rock-table-wrapper
            .rock-table
              .text-center
                b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
              div.text-center.mb-5(v-if="!isRecord") {{ message }}
              div(v-if="isRecord")
                table.table
                  thead.thead-dark.small
                    tr
                    th.align-middle(scope="col") 日付
                    th.align-middle(scope="col") 岩場
                      br
                      |岩 
                      br 
                      |ルート
                    th.align-middle(scope="col") 区分
                  tbody
                    tr.small.recordList(v-for="item in pageData" v-bind:key="item.Id" @click="callRecordEdit(item.id)")
                      td.align-middle {{ item.climbDate.slice(0, 10).replace(/-/g, '/') }}
                      td.align-middle {{ item.rockyName }} 
                        br
                        | {{ item.rockName }} 
                        br
                        |
                        span(v-html="(item.routeName ? item.routeName : '(ルート名なし)') + (item.gradeName ? '　' + item.gradeName : '')")
                      td.align-middle.mt-4
                        .d-block.d-sm-none.badge.climbKind.p-2(:class="{'badge-primary': item.climbKind!=4, 'badge-secondary': item.climbKind==4}") {{ item.climbKindName.slice(0, 1) }}
                        .d-none.d-sm-block.badge.climbKind.p-2.climbKindWidth(:class="{'badge-primary': item.climbKind!=4, 'badge-secondary': item.climbKind==4}") {{ item.climbKindName }}
              .mb-5
                nav
                  ul.pagination.justify-content-center
                    li.page-item(:class="{disabled: page == 1}")
                      button.page-link(@click.prevent="changePage('back')" :disabled="page == 1") ≺ 前へ
                    li.page-item.disabled
                      a.page-link ページ {{ page }} / {{ lastPage }}
                    li.page-item(:class="{disabled: page == lastPage}")
                      button.page-link(@click.prevent="changePage('next')" :disabled="page == lastPage") 次へ ≻
</template>

<script>
import axios from 'axios'

// 1ページの表示件数
const PAR_PAGE = process.env.VUE_APP_PAR_PAGE

export default {
  data() {
    return {
      data: [],
      climbKind: '',
      page: 1,
      lastPage: 1,
      isLoading: true,
      isRecord: false,
      message: '',
    }
  },
  created() {
    //記録の取得
    axios.get(process.env.VUE_APP_API_GATEWAY + '/api-record',  {
      headers: {
        'Authorization': this.$user.idToken
      }
    })
    .then(res => {
      this.data = res.data
      if(res.data.length > 0){
        for(let i=0; i<res.data.length;i++) {
          if (res.data[i].climbKind == "1") {
            this.data[i].climbKindName = 'オンサイト'
          }else if(res.data[i].climbKind == "2") {
            this.data[i].climbKindName = 'フラッシュ'
          }else if(res.data[i].climbKind == "3") {
            this.data[i].climbKindName = '完登'
          }else if(res.data[i].climbKind == "4") {
            this.data[i].climbKindName = 'チャレンジ中'
          }
        }
        this.page = 1
        this.lastPage = this.data.length / PAR_PAGE
        this.lastPage = Math.ceil(this.lastPage)
      }
      this.isRecord = true
      this.isLoading = false
    })
    .catch(err => {
      this.message = '情報の取得に失敗しました'
      this.isLoading = false
    })
  },
  methods: {
    //「記録する」ボタン押した時
    callRecordEdit(id) {
      
      if(id) {
        this.$router.push({ name: 'record_edit', query:{ id: id }})
      }else {
        this.$router.push('/record/edit')
      }
    },
    //「前へ」「次へ」押下時
    changePage(e) {
      if(e == 'back') {
        this.page--
      }else if(e == 'next') {
        this.page++
      }
      //スクロール位置を一番上にする
      window.scrollTo(0,0)
    }
  },
  // 算出プロパティ
  computed: {
    pageData() {
      return this.data.slice((this.page-1) * PAR_PAGE, this.page * PAR_PAGE)
    }
  }
}
</script>

<style lang="stylus"scoped>
.recordList
  cursor pointer
  background-color #EEEEEE
  &:hover
    background-color #DDDDDD
  &:active
    background-color #CCCCCC
.climbKind
  font-size 0.8rem
.climbKindWidth
  width 100px
</style>